import React from 'react'

const Trending = () => {
	return (
		<>
			<section
				className='trend'
				data-aos='fade-up'
				data-aos-offset='100'
				data-aos-delay='40'
				data-aos-duration='800'
				data-aos-easing='ease-in-out'
			>
				<div className='trendWrap'>
					<div className='textD'>
						<h5>trending techs</h5>
					</div>
					<div className='list_trend'>
						<span>
							<img
								src='https://www.mindinventory.com/static/media/flutter.e69eaaa3.svg'
								alt='flutter_dre'
							/>
							<p>flutter</p>
						</span>
						<span>
							<img
								src='https://www.mindinventory.com/static/media/golang.0ed41fab.svg'
								alt='flutter_dre'
							/>
							<p>flutter</p>
						</span>
						<span>
							<img
								src='https://www.mindinventory.com/static/media/react.47a88250.svg'
								alt='flutter_dre'
							/>
							<p>flutter</p>
						</span>
						<span>
							<img
								src='https://www.mindinventory.com/static/media/flutter.e69eaaa3.svg'
								alt='flutter_dre'
							/>
							<p>flutter</p>
						</span>
						<span>
							<img
								src='https://www.mindinventory.com/static/media/firebase.60917cff.svg'
								alt='flutter_dre'
							/>
							<p>flutter</p>
						</span>
						<span>
							<img
								src='https://www.mindinventory.com/static/media/docker.4ac45108.svg'
								alt='flutter_dre'
							/>
							<p>flutter</p>
						</span>
					</div>
				</div>
			</section>
		</>
	)
}

export default Trending
