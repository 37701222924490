import React from 'react'

const Services = () => {
	return (
		<>
			<section className='services'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>Services</h5>
					<h3>What we'll offer.</h3>
				</div>

				<div className='service_s'>
					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>Branding</h2>
							<span className='span_3'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_3'>
							<p>
								When it comes to application design or website design the burning
								question is how it should not only represent what your business is all
								about but to also allow users to easily navigate through the complete
								solution without having to learn new things to use the app.
							</p>
							<p>
								With average user attention span dropping from 12 seconds to 10 we
								understand that the onus is on how the solution makes the user feel when
								they first interact hence we follow the latest trends and understand
								what it requires to design successful apps and web solutions.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/figma.b708b2af.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/xd.30776f24.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/zeplin.56322834.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/invision.df086033.webp'
									alt=''
								/>
							</div>
						</article>
					</div>

					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>Web development</h2>
							<span className='span_2'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_2'>
							<p>
								A web app, dashboard or admin panel nowadays is a must for any business
								who is looking to expand & grow in this ever changing digital era of
								machine learning, artificial intelligence and smart cities where users
								are expecting their day to day activities to be done online.
							</p>
							<p>
								Whether you are looking to develop a CMS, eCommerce or an admin
								dashboard, we do have a large team who is not only expert in these areas
								but also passionate towards learning new technologies so that we can
								provide you a consultation if you are looking to migrate your legacy
								systems.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/angular.eb050347.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/node.7ded16d6.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/php.e6ee094e.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/laravel.d9e956e7.webp'
									alt=''
								/>
							</div>
						</article>
					</div>

					<div
						className='serve'
						data-aos='fade-up'
						data-aos-offset='100'
						data-aos-delay='40'
						data-aos-duration='800'
						data-aos-easing='ease-in-out'
					>
						<div className='headS'>
							<h2>app development</h2>
							<span className='span_1'>
								<ion-icon name='chevron-down-outline'></ion-icon>
							</span>
						</div>

						<article className='article_1'>
							<p>
								With so many ways to build mobile apps, we have expertise in choosing
								what’s a best solution for your mobile app development strategy either
								its in Flutter or with native iOS and Android.
							</p>
							<p>
								Having delivered more than 700+ mobile applications and some of them
								being featured in respective app stores we understand what it requires
								to build a mobile app that is not only unique but easy to use and
								acceptable for users.
							</p>

							<div className='techIMG'>
								<img
									src='https://www.mindinventory.com/static/media/react.4e28012a.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/android.4dc32be6.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/Ionic.be3d180c.webp'
									alt=''
								/>
								<img
									src='https://www.mindinventory.com/static/media/android.4dc32be6.webp'
									alt=''
								/>
							</div>
						</article>
					</div>
				</div>
			</section>
		</>
	)
}

export default Services
