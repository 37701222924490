import React from 'react'

const Nav = () => {
	return (
		<>
			<nav>
				<div className='navInner'>
					<div className='logo animate__fadeInLeft'>
						<h1>Dre's Tech.</h1>
					</div>

					<div className='menuWrap animate__fadeInRight'>
						<div className='cageMenu'>
							<div className='hamburger'>
								<span className='span1'></span>
								<span className='span2'></span>
							</div>

							<h3 className='menuT'>Menu</h3>
						</div>

						<div className='_smallNav'>
							<h1 className='menuH'>
								Learn more <a href='http://'>about us</a>, check out
								<a href='http://'>our work</a>, the latest on
								<a href='http://'>instagram</a> and <a href='http://'>contact us.</a>
							</h1>
						</div>
					</div>
				</div>
			</nav>
		</>
	)
}

export default Nav
