import React from 'react'

const Blog = () => {
	return (
		<>
			<section className='blog'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>news and blog</h5>
				</div>
				<div className='blogWrap'>
					<a
						href='#0'
						className='blogBlock'
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<span className='blogI'>
							<img
								src='https://www.mindinventory.com/blog/wp-content/uploads/2021/05/react-native-app-development-cost.png'
								alt=''
							/>
						</span>

						<span className='blogT'>
							<h4>how much does it cost to build me?</h4>
						</span>
					</a>

					<a
						href='#0'
						className='blogBlock'
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<span className='blogI'>
							<img
								src='https://www.mindinventory.com/blog/wp-content/uploads/2021/05/react-libraries.png'
								alt=''
							/>
						</span>

						<span className='blogT'>
							<h4>how much does it cost to build me?</h4>
						</span>
					</a>

					<a
						href='#0'
						className='blogBlock'
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<span className='blogI'>
							<img
								src='https://www.mindinventory.com/blog/wp-content/uploads/2021/05/whitespace-in-ui-design.png'
								alt=''
							/>
						</span>

						<span className='blogT'>
							<h4>how much does it cost to build me?</h4>
						</span>
					</a>

					<a
						href='#0'
						className='blogBlock'
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<span className='blogI'>
							<img
								src='https://www.mindinventory.com/blog/wp-content/uploads/2021/04/how-to-develop-a-gps-navigation-app.png'
								alt=''
							/>
						</span>

						<span className='blogT'>
							<h4>how much does it cost to build me?</h4>
						</span>
					</a>
				</div>

				<p className='exploreB'>
					<a href='http://'>explore more +</a>
				</p>
			</section>
		</>
	)
}

export default Blog
