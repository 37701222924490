import React from 'react'

const Landing = () => {
	return (
		<>
			<section className='landing'>
				<div className='curveL'>
					<div className='curveT'>
						<h3 className='spanT'>
							<span className='animate__fadeInUp'>design.</span>
							<span className='animate__fadeInUp'>develop.</span>
							<span className='animate__fadeInUp'>deliver.</span>
						</h3>

						<p className='PT animate__fadeInUp'>
							We help startups and enterprises launch and scale with digital products
							and effective strategies that makes them standout in the market both
							online and offline.
						</p>

						<p className='PT2 animate__fadeInUp'>
							Check out our <a href='http://'>recent work</a> or learn more on
							<a href='http://'>what we do.</a>
						</p>
					</div>

					<div className='curveIMG'>
						<div className='imgBox'>
							<img src='https://enyata.com/images/what-we-do-img.png' alt='' />
						</div>

						<div className='attatch'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								className='h-5 w-5'
								viewBox='0 0 20 20'
								fill='currentColor'
							>
								<path
									fillRule='evenodd'
									d='M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
									clipRule='evenodd'
								/>
							</svg>

							<p>Best in className engineering for the finest ideas.</p>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default Landing
