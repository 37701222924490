import React from 'react'

const Display = () => {
	return (
		<>
			<section className='display'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>SUCCESS STORIES</h5>
					<h3>Projects we helped our clients to achieve success.</h3>
				</div>
				<ul className='projects'>
					<li
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<a href='http://'>
							<div className='descImg'>
								<img
									src='https://cregital.com/wp-content/uploads/2020/02/Cregital-TEF.jpg'
									alt=''
								/>
							</div>

							<div className='desc'>
								<h3>Tony Elumelu Website</h3>
								<p>website</p>
							</div>
						</a>
					</li>

					<li
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<a href='http://'>
							<div className='descImgSmall'>
								<img
									src='https://cregital.com/wp-content/uploads/2020/03/heirs-holdings.jpg'
									alt=''
								/>
							</div>

							<div className='desc'>
								<h3>Heirs Holding</h3>
								<p>website</p>
							</div>
						</a>
					</li>

					<li
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<a href='http://'>
							<div className='descImgSmall'>
								<img
									src='https://cregital.com/wp-content/uploads/2016/02/Zenith-HQ.jpg'
									alt=''
								/>
							</div>

							<div className='desc'>
								<h3>Zenith Bank</h3>
								<p>website</p>
							</div>
						</a>
					</li>

					<li
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<a href='http://'>
							<div className='descImg'>
								<img
									src='https://cregital.com/wp-content/uploads/2020/02/Cregital-TEF.jpg'
									alt=''
								/>
							</div>

							<div className='desc'>
								<h3>Tony Elumelu Website</h3>
								<p>website</p>
							</div>
						</a>
					</li>

					<li
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<a href='http://'>
							<div className='descImg'>
								<img
									src='https://cregital.com/wp-content/uploads/2020/02/Cregital-TEF.jpg'
									alt=''
								/>
							</div>

							<div className='desc'>
								<h3>Tony Elumelu Website</h3>
								<p>website</p>
							</div>
						</a>
					</li>

					<li
						data-aos='zoom-in-up'
						data-aos-offset='150'
						data-aos-delay='40'
						data-aos-duration='600'
						data-aos-easing='ease-in-out'
					>
						<a href='http://'>
							<div className='descImgSmall'>
								<img
									src='https://cregital.com/wp-content/uploads/2016/02/Zenith-HQ.jpg'
									alt=''
								/>
							</div>

							<div className='desc'>
								<h3>Zenith Bank</h3>
								<p>website</p>
							</div>
						</a>
					</li>
				</ul>
			</section>
		</>
	)
}

export default Display
