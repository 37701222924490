import React from 'react'

const MessageForm = () => {
	return (
		<>
			<div className='message'>
				<ion-icon name='chatbubble-outline'></ion-icon>
			</div>

			<div className='messageForm'>
				<div className='_mailWrapper'>
					<div className='_mailText'>
						<h1>Drop us a line</h1>
						<p>Got a question or proposal, or just want to say hello? Go ahead.</p>
					</div>

					<form className='_mailForm'>
						<div className='_row'>
							<div className='rowI'>
								<label htmlFor='name'> Your Name </label>
								<input type='text' name='name' placeholder='Enter your name' />
							</div>
							<div className='rowI'>
								<label htmlFor='email'> Email Address </label>
								<input
									type='email'
									name='email'
									id='email'
									placeholder='Enter your email address'
								/>
							</div>
						</div>

						<div className='_row'>
							<div className='rowT'>
								<label htmlFor='name'>
									Your Message
									<span>
										<span className='count'> </span>
									</span>
								</label>
								<textarea
									type='text'
									name='message'
									id='message'
									placeholder='Briefly tell us about your project.'
								></textarea>
							</div>
						</div>

						<div className='_row'>
							<button type='submit' disabled='{state.submitting}' className='shoot'>
								shoot
								<img
									src='https://devdanielcodes.vercel.app/img/Arrow.442f3667.svg'
									alt=''
								/>
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	)
}

export default MessageForm
