import React from 'react'

const GetInTouch = () => {
	return (
		<>
			<section className='cta'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>get in touch</h5>
					<h3>say hello!</h3>
				</div>
				<div
					className='ctaWrap'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='800'
					data-aos-easing='ease-in-out'
				>
					<p>
						Contact us to find out how we can help you. If it’s easier you can email
						us at <a href='mailto:'>hello@dretech.com</a> or call
						<a href='tel:+'>(+234) 08020965367.</a> Download the
						<a href='!0' download="Dre's Tech Brochure">
							Dre's Tech brochure.pdf
						</a>
						(5mb).
					</p>
				</div>
			</section>
		</>
	)
}

export default GetInTouch
