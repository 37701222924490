import React from 'react'

const WhoWeAre = () => {
	return (
		<>
			<section className='who'>
				<div
					className='textD'
					data-aos='fade-up'
					data-aos-offset='100'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<h5>who are we</h5>
					<h3>
						We make custom Mobile Apps, Web & Enterprise Solutions for Startups,
						Enterprises & Mid-Scale Businesses.
					</h3>
					<p>
						<a href='http://'>read more +</a>
					</p>
				</div>

				<article
					data-aos='fade-up'
					data-aos-offset='150'
					data-aos-delay='40'
					data-aos-duration='600'
					data-aos-easing='ease-in-out'
				>
					<p>
						The top-notch and preeminent Web and Mobile App Development Company of
						India with its global presence in USA, MindInventory is first-rate choice
						of the clients worldwide. With sheer customer satisfaction in mind, we are
						profoundly dedicated to developing highly intriguing apps that strictly
						meet the business requirements and catering a wide spectrum of projects.
					</p>
				</article>

				<div className='exp'>
					<div className='expWrap'>
						<h3
							data-aos='fade-up'
							data-aos-offset='150'
							data-aos-delay='40'
							data-aos-duration='600'
							data-aos-easing='ease-in-out'
						>
							<span>160+</span>
							<p>professionals</p>
						</h3>

						<h3
							data-aos='fade-up'
							data-aos-offset='150'
							data-aos-delay='40'
							data-aos-duration='600'
							data-aos-easing='ease-in-out'
						>
							<span>5+</span>
							<p>years of average experience</p>
						</h3>

						<h3
							data-aos='fade-up'
							data-aos-offset='150'
							data-aos-delay='40'
							data-aos-duration='600'
							data-aos-easing='ease-in-out'
						>
							<span>100M+</span>
							<p>investments received clients</p>
						</h3>
					</div>
				</div>
			</section>
		</>
	)
}

export default WhoWeAre
